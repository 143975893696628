import imagemDominio from "./imagembh.png";
import "./App.css";

function App() {
  return (
    <div className="App">
      <title>BabyHero.com.br</title>
        <h1 className="title">babyhero.com.br</h1>
        <p>Este domínio único está disponível para venda!</p>
        <p className="convincing-text">Entre em contato agora mesmo,</p>
        <p className="contact-email">
          envie um e-mail para:{" "}
          <a href="mailto:devmatheus@gmail.com">devmatheus@gmail.com</a>
        </p>
        <img className="imagem" src={imagemDominio} />
    </div>
  );
}

export default App;
